@import "./mixins";
$featured-color: #154733;

#primary {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 40vh;
  padding: 0 10%;
  // background-color: #222222;
  // background-position: center;
  // background-blend-mode: overlay;
  color: white;
  h1 {
    font-size: 48px;
    text-transform: uppercase;
    font-family: "Raleway";
    margin-top: 0;
  }
  h2 {
    font-size: 36px;
    margin: 0;
    font-weight: 300;
  }
}

#secondary {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 11vw;
  background-color: #d9b382;
  padding-left: 5%;
  color: white;
}

#accent {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 11vw;
  background-color: green;
  padding-left: 5%;
  color: white;
}
.header-heading {
  font-size: 16px;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-heading:hover {
  cursor: pointer !important;
  text-decoration: underline;
}
.logo2 {
  max-width: 50px;
  max-height: 50px;
  padding-left: 2vw;
}

.text-col {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  .pricing-col {
    margin-top: 5%;
    h1 {
      font-size: 48px;
    }
  }
}
.flex-cont2 {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.flex-cont4 {
  display: flex;
  width: 100%;
  min-height: 20px;
  margin-bottom: 2vw;
  justify-content: space-between;
  margin-left: 3vw;
  width: 91%;
  border-bottom: 1px solid silver;
}
.acc-head {
  font-size: 1.3vw;
  width: 87%;
}
.bi-bt {
  width: 2.5vw;
  height: 2.5vw;
  cursor: pointer;
}
.message {
  padding: 0px 3.5vw;
  width: 92%;
  font-size: 1vw;
}
.freq {
  font-size: 2vw;
  margin: 0;
}
.flex-cont3 {
  display: flex;
  width: 100%;
  min-height: 20px;
  justify-content: center;
  margin: 2vw 0;
}
.header-cont {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.logos {
  width: 13vw;
  height: 13vw;
}
.para {
  font-size: 3vw;
}
.one {
  width: 7vw;
  height: 4vw;
}
.ir-head {
  font-size: 1.6vw;
}
.ir-para {
  font-size: 1.5vw;
  text-align: center;
}
.sc {
  width: 100%;
  height: 100%;
}
.para2 {
  font-size: 2vw;
  color: #696666;
}
.inside {
  display: flex;
  justify-content: left;
  margin-top: -1.5vw;
  width: 100%;
  min-height: 20px;
}
.headers {
  padding-top: 5px;
  padding-bottom: 5px;
  // display: grid;
  // grid-template-columns: 1fr auto 1fr;
  width: 100%;
  // min-height: 20px;
  text-align: center;
  background-color: white;
  // max-height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-weight: 600;
    font-size: 28px;
    b {
      font-weight: 500;
    }
  }

  .site-logo {
    img {
      width: 110px;
    }
  }
  .welcome-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
  }
}
.foot {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20px;
  justify-content: space-between;
}
.flex-cont {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: 20px;
}
.header-text {
  font-size: 3.5vw;
  margin-right: 1vw;
}
.header-text2 {
  font-size: 3.5vw;
  margin-right: 1vw;
  font-size: 1.5vw;
}
.image-col {
  width: 20%;
}
.left {
  width: 33%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.inner-left {
  width: 20%;
  align-items: center;
  display: flex;
}
.inner-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.btn {
  height: 55px;
  font-size: 1rem;
  font-weight: bold;
  color: #000000;
  margin-top: 1.5vw;
  border-radius: 1vw;
  outline: none;
  background-color: white;
  border: 1px solid silver;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 200px;
  &:hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
}
.profile-wrapper {
  .header-heading {
    color: gray;
  }
}

.proceed {
  width: 85%;
  height: 2.5vw;
  font-size: 1vw;
  margin-top: 1vw;
  background-color: steelblue;
  color: white;
  border: none;
  outline: none;
}
.btn3 {
  max-width: 300px;
  max-height: 50px;
  font-size: 16px;
  background-color: #98032e;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  height: 5vw;
}
.btn3:hover {
  cursor: pointer !important;
  background-color: black;
}

.btn2 {
  color: white;
  background-color: steelblue;
  width: 30%;
  height: 5vw;
  border: none;
  outline: none;
  border-radius: 1vw;
}
.right {
  width: 57%;
  margin-left: 6%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  border: 2px solid gray;
}
.right-header {
  background-color: #000000;
  height: 4vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-cont {
  width: 45%;
  display: flex;
  height: 18vw;
  align-items: center;
  justify-content: center;
}
.right-cont {
  width: 55%;
  display: flex;
  max-height: 23vw;
  flex-direction: column;
  overflow-y: scroll;
}
.footer-logos {
  width: 4vw;
  height: 4vw;
  margin-top: 6.8vw;
  margin-left: 3vw;
}
.foot-texts {
  font-size: 1.5vw;
  margin-bottom: -0.5vw;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.inputs {
  width: 85%;
  margin-top: 0.4vw;
  height: 2vw;
  font-size: 1vw;
  border: 1px solid silver;
}
.phone-input {
  width: -webkit-fill-available;
  height: 35px;
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

.bottom-texts {
  font-size: 1.5vw;
  color: white;
}
.logo-texts {
  font-size: 20px;
  color: white;
  margin-left: -42vw;
  margin-top: 7.3vw;
}
.banner {
  width: 100%;
  text-align: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3% 0;
  color: white;
  .btn {
    padding: 0 5%;
    width: 300px;
    height: 50px;
    font-size: 16px;
  }
  .btn:hover {
    background: black;
    color: white;
    cursor: pointer;
  }
  .subtitle {
    font-size: 36px !important;
    font-weight: 400;
    text-transform: uppercase;
  }
}
.how-it-works {
  h3 {
    color: white;
    font-size: 24px !important;
  }
}
.how-it-works-container {
  background-color: gainsboro;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  .card {
    border: 1px solid black;
    padding: 1rem;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 600px;
  }

  .step-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    @include max-mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .stepNumber {
    font-size: 24px;
    font-weight: 600;
    padding: 0px 30px;
    white-space: nowrap;
    @include max-mobile {
      padding: 5px 15px;
    }
  }
  .stepText {
    font-size: 18px;
    padding: 15px 30px;

    @include max-mobile {
      padding: 5px 15px;
      margin-bottom: 15px;
    }
  }
}
h3 {
  color: black;
  font-size: 24px !important;
  text-align: center;
}
.banner-paras {
  font-size: 42px;
}
#margin {
  margin-top: 4vw;
}

.footer-logo-cont-small {
  display: none;
}

.footers {
  width: 100%;
  border: 1px solid red;
}

.footer {
  background: black;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
}
.badge-cont {
  display: flex;
  align-items: center;
  height: 150px;
}
.footer-badge {
  margin-right: 10px;
  height: 80px;
}
.links-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.copyright-cont {
  display: flex;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}
.pricing-container {
  display: flex;
  flex-direction: row;
}

.whole-footer {
  background: black;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.cc-form {
  width: 500px;
  margin: auto;
  .btn {
    background-color: green;
    color: white;
    font-size: 1rem;
  }
}

.swal2-popup {
  padding: 30px 30px 50px 30px !important;
  border-radius: 0px !important;
}

.swal2-title {
  color: #000000 !important;
}

.modal-text {
  font-family: "Raleway";
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.modal-footer {
  font-family: "Raleway";
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.modal-footer a {
  color: #52acff;
  cursor: pointer;
}

.swal2-input {
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 18px !important;
  line-height: 42px;
  color: #000000 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.swal2-input[type="number"] {
  max-width: 100% !important;
}

.swal2-styled.swal2-confirm {
  background: #52acff !important;
  border-radius: 18px !important;
  padding: 20px 57px !important;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  align-items: center;
  text-align: center;
}

.swal2-footer {
  border-top: none !important;
}

.swal2-popup {
  width: 50em !important;
}

.circle::before {
  // background-color: orange;
  border-radius: 1em;
  content: " ";
  display: inline-block;
  height: 0.8em;
  margin-right: 6px;
  width: 0.8em;
  margin: 0 20px 0 0;
}
.circle {
  // color: orange;
  text-decoration: none;
  display: block;
  margin-top: 16px;
}

.faq-container {
  margin: 0% 5% 5%;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  .freq {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .swal2-styled.swal2-confirm {
    padding: 20px 30px !important;
    font-size: 14px !important;
  }
  .logo2 {
    width: 60px;
    height: 60px;
    padding: 0;
  }
  #secondary {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-col {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .headers {
    justify-content: center;
    align-items: center;
  }
  .header {
    height: 140px;
  }
  .header-heading {
    font-size: 16px;
  }
  .inner-left {
    margin-top: 20px;
  }
  .flex-cont2 {
    flex-direction: column;
  }
  .left {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .image-col {
    display: none;
  }
  .header-text {
    font-size: 48px;
    align-items: center;
  }
  .header-text2 {
    margin-top: -5vw;

    font-size: 30px;
    font-weight: lighter;
    align-items: center;
  }
  .banner-paras {
    font-size: 22px;
    align-items: center;
  }
  .inside {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .para {
    font-size: 36px;
  }
  .para2 {
    font-size: 24px;
    margin-top: 3vw;
    z-index: 10000;
  }
  .one {
    width: 90px;
    height: 55px;
  }
  .ir-head {
    font-size: 24px;
  }
  .ir-para {
    font-size: 24px;
    margin-top: 7vw;
    text-align: center;
  }

  .right {
    width: 90%;
    padding: 0;
    margin-left: 0px;
  }
  .sc {
    margin: 0;
  }
  .btn3 {
    height: 54px;
    margin-bottom: 1vw;
    :hover {
      cursor: pointer !important;
    }
  }
  .acc-head {
    font-size: 18px;
  }
  .bi-bt {
    width: 20px;
    height: 20px;
    margin-top: 4.5vw;
  }
  .message {
    font-size: 12px;
  }
  .freq {
    font-size: 36px;
  }
  .flex-cont3 {
    text-align: center;
  }
  .foot {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  .logo-texts {
    margin-left: 0;
    margin-top: 15vw;
    font-size: 15px;
  }
  .foot-texts {
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
  .footer-logos {
    display: none;
    margin-top: -18vw;
    margin-left: -5vw;
  }
  .footer-logo-cont-small {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }
  .footer-logo {
    width: 60px;
    height: 60px;
  }
  .footer-col {
    width: 100%;
  }
  .bottom-texts {
    font-size: 4vw;
    margin-top: 40px;
  }
  .footer {
    background: black;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0px 0px;
  }
  .badge-cont {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
  }
  .footer-badge {
    margin-right: 0px;
    height: 30px;
  }
  .links-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
