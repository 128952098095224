// Mixins for responsive breakpoints

@mixin md {
  @media screen and (max-width: 960px) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: 600px) {
    @content;
  }
}

.App {
  text-align: center;
  background: green;
}
body {
  font-family: "Raleway";
}
.center {
  justify-content: center;
  text-align: center;
}
.badge {
  color: black;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  background-image: linear-gradient(to right, #c6c5c5 0%, #808285 100%);
}
.btn-main {
  background-color: #98032e !important;
  color: white !important;
  padding: 0 10px;
}
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  z-index: 9999;
}

// game detail modal styles

.swal2-content {
  @include sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.game-detail-single {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  @include md {
    flex-direction: column;
    justify-content: center;
  }

  .image {
    // max-width: 50px;
    max-height: 100%;
    margin-left: 10px;
    margin-right: 30px;

    @include md {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .name {
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }
  .date {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
    margin-left: auto;

    @include md {
      margin-left: unset;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    @include md {
      flex-direction: column;
    }
  }
}

.game-detail-empty {
  h3 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    color: #808285;
  }
}

.register_terms_description {
  margin: 0;
}
.register_terms_link {
  color: blue;
}
